/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: #121212;}

.page-link {
  color: #29B6F6;
}
.page-link:visited {
  color: #9575CD;
}
.page-link:link { 
  text-decoration: none; 
} 
.page-link:visited { 
  text-decoration: none; 
} 
.page-link:hover { 
  text-decoration: underline; 
} 
